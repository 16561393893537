	

<div class="slider-home" style="background-image: url('https://demo.tushow.live/assets/img/banner_home.jpg')">


	<div class="animate__animated animate__backInDown">
		
		<img src="https://demo.tushow.live/assets/img/logo-big.png" width="275">

		<h1>Tu Show Tech</h1>

	</div>
	
</div>
<div class="container-informativo" style="padding-top: 20px;"></div>


<router-outlet></router-outlet>